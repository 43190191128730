<template>
  <div>
    <section class="PublicFundProcess">
      <div class="publicfundprocess_cont">
        <div class="title_">
          <router-link :to="{ name: 'Hui' }" class="iconfont arrleft"
            >&#xe665;</router-link
          >
          惠金宝
        </div>
        <div class="side_cont">
          <ProgressBar :sideData="side" />
        </div>
        <div class="solids_"></div>
        <div class="play_type">
          <div class="ti_">提现到银行卡</div>
          <div class="bank_card">
            <div class="bank_list">
              <span class="c_name">{{ pay.bankName }}&emsp;{{ lastnum }}</span>
              <span class="r_share">单笔200万，日累计200万</span>
            </div>
          </div>
          <div  class="bankcard_">
            <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i"
              class="banks_">
              <div class="top_ban">
                <img :src="item.logoUrl" alt="">
                <span class="bank_name">{{item.bankName||''}}</span>
              </div>
              <div class="cardnum_">{{item.bankAccount||''}}</div>
              <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
            </div>
            <a @click="addcard" class="addbank_">
              <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                  style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
              <span class="add_">添加银行</span>
            </a>
          </div>
          <div class="smartFund">提现方式</div>
          <div class="recharge">
            <div
              class="recharge1"
              @click="myborder(1)"
              :class="border == 1 ? 'getborder' : ''"
            >
              <h3>极速取现</h3>
              <div class="recharge-text">
                <p>最快</p>
                <div>1分钟</div>
                <p>到账，今日剩余额度</p>
                <div>{{ maxmoney.toFixed(2) }}元</div>
              </div>
              <div v-if="border == 1" class="right_true"><span>✓</span></div>
            </div>
            <div
              class="recharge1"
              @click="myborder(2)"
              :class="border == 2 ? 'getborder' : ''"
            >
              <h3>普通取现</h3>
              <div class="recharge-text">
                <p>预计</p>
                <div>T+2个交易日</div>
                <p>到账，无限额</p>
              </div>
              <div v-if="border == 2" class="right_true"><span>✓</span></div>
            </div>
          </div>
          <div class="notice">
            <div>提现须知:</div>
            <p>1.惠金宝中的货基产品支持7×24小时极速提现</p>
            <p>2.单只货币基金单日极速取现的最高额度为1万元。</p>
            <p>3.极速取现的额度每日24:00以后重置</p>
            <p>4.每日15:00后的普通提现申请，将作为下一个交易日的申请。</p>
            <p>5.个别银行可能存在到账延迟，具体到账时间以银行到账时间为准</p>
            <p>6.极速提现当日无收益，普通提现份额确认当日无收益</p>
          </div>
          <div class="input_share">
            <div class="ti_">
              提现金额 <span>账户金额:{{ totalFund }}</span>
            </div>
            <div class="input_box">
              <input
                class="input_"
                v-model="numprice"
                type="number"
                placeholder="请输入提现金额(元)"
              />
              <span class="fen_s">&emsp;元</span>
            </div>
          </div>
          <div class="sefe">
            <img src="@/static/img/detail/safe.png" alt="" />
            <span>为了您的资金安全，交易遵守</span>
            <span class="card">同卡进出</span>
          </div>
          <div class="myCheck">
            <el-checkbox v-model="checked"></el-checkbox>
            <div class="mydoc">
              阅读并同意
              <a v-for="item in protocolArr" :key="item.id" @click="XY(item)">{{
                item.protNameDesc
              }}</a>

              等内容
            </div>
          </div>
          <div class="pay">
            <div class="payMoney">
              <div>待提现</div>
              <span>{{ numprice || "--" }}</span>
            </div>
            <a class="next" @click="nextstep">下一步</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import { bankInfo, subtractFastRedeem ,findXjbAccoList} from "@/api/getMoney";
import { protPage } from "@/api/other/index.js";

export default {
  name: "GetMoney",
  components: { ProgressBar },
  beforeCreate() {
    let token = this.$store.getters.token;
    let _userInfo = localStorage.getItem("customerInfo");
    if (!token || !_userInfo) {
      this.$message.warning("请登录!");
      this.$router.push({ name: "Login" });
      return;
    }
    let userInfo = JSON.parse(_userInfo);
    if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
      this.$message.warning("请先开户!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
      this.$message.warning("请补充资料!");
      this.$router.push({ name: "PersonalDataSupply" });
      return;
    }
    if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
      this.$message.warning("请设置交易密码!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
      this.$message.warning("请先完成风险测评!");
      this.$router.push({ name: "RiskAssessment" });
      return;
    }
  },

  data() {
    return {
      totalFund: 0,
      protocolArr: [],
      xyArr: [], //产品协议
      syed: "",
      play:[],
      cardindex:'0',
      checked: false,
      disabled: true,
      border: 1,
      trs: {
        title1: "基金",
        title2: "时间",
        title3: "最新净值",
        title4: "日涨幅",
        title5: "操作",
      },
      side: [
        { title: "填写提现信息", type: 2 },
        { title: "输入交易密码", type: 0 },
        { title: "交易结果", type: 0 },
      ],
      pay: {},
      bankindex: 0, //默认是索引第一个
      numprice: null,
      lastnum: "",
      maxmoney: 0,
      /** 基金协议 */
      agree1: "",
      agree2: "",
      agree3: "",
      agree4: "",
      agree5: "",
      annAndProtlist: [], //基金协议及公告
      contract: [], //产品合同
      outline: [], //产品概要
      instructions: [], //说明书
    };
  },
  methods: {
    addcard() {
      this.$router.push({ name: "BankCardAdd" });
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.pay = this.play[i]; //切换银行卡
      let objs = JSON.stringify(this.pay);
      localStorage.setItem("banckdata", [objs]);
      this.lastnum = this.pay.bankAccount.substr(-4, 4);
      this.totalFund=this.pay.enableShare;
      this.subtractFastRedeem();
    },
    subtractFastRedeem() {
      subtractFastRedeem({tradeAcco:this.pay.tradeAcco}).then((res) => {
        console.log(res);
        this.maxmoney = res.data;
      });
    },

    /** 获取产品协议 */
    getProtocol() {
      protPage({
        protType: "1",
        status: "1",
        protLink: "8",
      }).then((res) => {
        const {
          code,
          data: { data = [] },
        } = res;
        if (code == 200) {
          const arr = data.filter((v) => v.protNameDesc.includes("取现"));
          this.protocolArr = [...arr];
        }
      });
    },
    XY(item) {
      const protUrl = item.protUrl;
      if (!protUrl) {
        this.$message({
          message: "管理员暂未上传该协议！",
          type: "error",
        });
        return;
      }
      // 协议详情
      window.open(`/pdfs/web/viewer.html?file=${encodeURI(protUrl)}`, "_blank");
    },
    mybank() {
      findXjbAccoList({}).then((res) => {
        this.pay = res.data[0];
        this.play=res.data;
        this.lastnum = this.pay.bankAccount.substr(-4, 4);
        let obj = JSON.stringify(this.pay);
        localStorage.setItem("banckdata", [obj]);
        this.totalFund=this.pay.enableShare;
        this.subtractFastRedeem();
        
      });
    },
    nextstep() {
      if (this.numprice < 0.01) {
        this.$message.error("惠金宝0.01元起提现！");
      } else if (
        this.numprice > 1 &&
        !/^[1-9]{1,}[0-9]*[.]?[0-9]*$/.test(this.numprice)
      ) {
        this.$message.error("请正确输入提现金额");
      } else if (parseFloat(this.numprice) > parseFloat(this.totalFund)) {
        this.$message.error("提现金额超出余额");
      } else if (this.checked) {
        this.disabled = false;
        sessionStorage.setItem("border", this.border);
        sessionStorage.setItem("numprice", this.numprice);
        this.$router.push({ name: "GetMoney2" });
      } else {
        this.$message.error("请阅读协议并勾选");
      }
    },
    myborder(n) {
      this.border = n;
    },
    bankswith(obj, i) {
      this.bankindex = i;
      this.shares = obj.share;
    },
  },
  created() {
    this.getProtocol();
  },
  mounted() {
    // this.totalFund = sessionStorage.getItem("totalFund");
    // console.log(this.totalFund)
    this.mybank();
    if (sessionStorage.getItem("border")) {
      this.border = sessionStorage.getItem("border");
    } else {
      this.border = 1;
    }
    if (sessionStorage.getItem("numprice")) {
      this.numprice = sessionStorage.getItem("numprice");
    }
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  color: #bdc0cb;
  font-size: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.PublicFundProcess {
  padding-bottom: 40px;
  min-height: 600px;
  display: flex;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      padding: 21px 40px 0px 43px;
      height: 60px;
      font-weight: 500;
      font-size: 20px;
      color: #1f1f1f;
      .arrleft {
        margin-right: 14px;
        color: #bdc0cb;
      }
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 40px;
      margin-top: 30px;
      .titles_ {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_ti {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
          font-weight: 700;
          text-align: LEFT;
          color: #1f1f1f;
        }
        .right_ti {
          font-size: 14px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
          .num_ {
            font-size: 36px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: 700;
            color: #7ba1de;
          }
          .fen_ {
            color: #7ba1de;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .right_btn {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 40px;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #000000;
        }
        img {
          width: 12px;
          height: 12px;
          margin-right: 12px;
        }
      }
      .card_new {
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px #edf1f7;
        margin-top: 12px;
        min-height: 108px;
        padding: 0px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .arrow {
          width: 25px;
          height: 7px;
          margin-left: 60px;
          margin-right: 60px;
          img {
            width: 25px;
            height: 7px;
          }
        }
        .card_l {
          display: flex;
          align-items: center;
          .l_num {
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #bdc0cb;
            margin-right: 10px;
          }
          .c_name {
            width: 140px;
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #1f1f1f;
          }
          .r_imgs {
            width: 60px;
            height: 60px;
            margin-left: 20px;
          }
        }
        .card_r {
          display: flex;
          align-items: center;
          .l_num {
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #bdc0cb;
            margin-right: 10px;
          }
          .c_name {
            width: 140px;
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #1f1f1f;
          }
          .r_imgs {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
        }
      }
    }
    .play_type {
      padding: 0px 40px;
      margin-top: 40px;
      .addCard {
        width: 518px;
        height: 46px;
        border: 1px dashed #e0e2e8;
        color: #bdc0cb;
        font-size: 14px;
        text-align: center;
        line-height: 48px;
        margin-bottom: 30px;
        cursor: pointer;
        .add {
          display: inline-block;
          transform: rotate(45deg);
        }
      }
      .ti_ {
        font-size: 16px;
        font-family: ABeeZee, ABeeZee-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #1f1f1f;
        margin-bottom: 14px;
      }
      .bank_card {
        .bank_list {
          width: 468px;
          height: 48px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          position: relative;
          margin-bottom: 24px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          .l_imgs {
            width: 26px;
            height: 26px;
            margin-right: 19px;
          }
          .c_name {
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #25293d;
            margin-right: 30px;
          }
          .r_share {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #ce9b63;
          }
          .right_true {
            position: absolute;
            right: -16px;
            top: -16px;
            width: 0px;
            height: 0px;
            border: 16px solid #f7d9b7;
            border-color: transparent transparent #eabf96 transparent;
            transform: rotate(45deg);
            // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
            span {
              color: #fff;
              position: absolute;
              transform: rotate(-35deg);
              top: -1px;
              right: -5px;
            }
          }
        }
        .bank_listac {
          border: 1px solid;
          border-image: linear-gradient(95deg, #f7d9b7 0%, #eabf96 100%) 1 1;
        }
      }
      .input_share {
        margin-top: 30px;
        .ti_ {
          font-size: 16px;
          font-family: ABeeZee, ABeeZee-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #1f1f1f;
          margin-bottom: 14px;
          span {
            color: #ce9b63;
          }
        }
        .input_box {
          display: flex;
          align-items: center;
          .input_ {
            display: flex;
            align-items: center;
            padding: 0px 24px;
            // justify-content: space-between;
            border: 1px solid #e0e2e8;
            border-radius: 2px;
            width: 520px;
            height: 46px;
          }
          .fen_s {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #1f1f1f;
            margin-right: 14px;
          }
        }
        .red_ti {
          margin-top: 19px;
          color: red;
          font-size: 12px;
        }
      }
    }
  }
  .smartFund {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 400;
    margin: 30px 0 14px 0;
  }
  .recharge {
    width: 849px;
    height: 162px;
    background: #f6f5f3;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 18px 0 18px;
    .recharge1 {
      width: 389px;
      height: 100px;
      background: #ffffff;
      border: solid 1px #fff;
      position: relative;
      .right_true {
        position: absolute;
        right: -16px;
        top: -16px;
        width: 0px;
        height: 0px;
        border: 16px solid #f7d9b7;
        border-color: transparent transparent #eabf96 transparent;
        transform: rotate(45deg);
        // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
        span {
          color: #fff;
          position: absolute;
          transform: rotate(-35deg);
          top: -1px;
          right: -5px;
        }
      }
      h3 {
        margin: 24px 0 20px 14px;
        font-size: 18px;
        font-weight: 400;
      }
      .recharge-text {
        display: flex;
        margin-left: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        div {
          color: #ce9b63;
        }
      }
    }
    .getborder {
      border: 1px solid;
      border-image: linear-gradient(95deg, #f7d9b7 0%, #eabf96 100%) 1 1;
    }
  }
  .bankcard_ {
  margin-bottom: 30px;
  padding: 30px 24px 6px 24px;
  background: #f6f5f3;
  display: flex;
  border-radius: 2px;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  .backs_ac {
    width: 208px !important;
    border: 1px solid #ce9b63;
    height: 54px !important;
    border-radius: 4px;
  }
  .banks_ {
    padding: 22px 20px;
    width: 210px;
    height: 56px;
    background: #fff;
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;
    border-radius: 4px;
    &:nth-child(3n + 3) {
      margin-right: 0px;
    }
    .right_true {
      position: absolute;
      right: -16.5px;
      top: -16.5px;
      width: 0px;
      height: 0px;
      border: 16px solid #f7d9b7;
      border-color: transparent transparent #eabf96 transparent;
      transform: rotate(45deg);
      // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
      span {
        color: #fff;
        position: absolute;
        transform: rotate(-35deg);
        top: -1px;
        right: -5px;
      }
    }
    .top_ban {
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        margin-right: 7px;
      }
      .bank_name {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        color: #1f1f1f;
      }
    }
    .cardnum_ {
      margin-top: 20px;
      font-size: 18px;
      font-family: Bahnschrift, Bahnschrift-Light;
      font-weight: 300;
      color: #1f1f1f;
    }
  }
  .addbank_ {
    width: 249px;
    height: 98px;
    border: 1px dashed #bdc0cb;
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    .add_ {
      font-size: 18px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
      font-weight: 400;
      color: #bdc0cb;
    }
  }
}
  .notice {
    width: 850px;
    height: 130px;
    background: rgba(255, 245, 234, 0.5);
    border-radius: 2px;
    margin-top: 14px;
    padding: 14px 0 14px 24px;
    font-size: 12px;
    color: #ce9b63;
    line-height: 18px;
  }
  .sefe {
    margin: 33px 3px 0 0;
    font-size: 14px;
    color: #1f1f1f;
    display: flex;
    align-items: center;
    .card {
      color: #ce9b63;
      font-weight: 400;
    }
  }
  .myCheck {
    width: 515px;
    display: flex;
    margin-top: 17px;
    font-size: 12px;
    line-height: 16px;
    input {
      margin-right: 10px;
    }
    a {
      color: #ce9b63;
    }
    .mydoc {
      margin-left: 10px;
    }
  }
  .pay {
    display: flex;
    justify-content: space-between;
    margin: 180px 0 44px 0;
    .payMoney {
      font-size: 12px;
      span {
        font-size: 26px;
        font-weight: 700;
        color: #e45247;
        line-height: 40px;
      }
    }
    .next {
      width: 200px;
      height: 48px;
      background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
      border-radius: 2px;
      text-align: center;
      line-height: 48px;
      color: #b88141;
    }
  }
}
</style>
